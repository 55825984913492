import { DataGrid } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminReport } from "../../redux/apiCalls";
import { publicRequest } from "../../requestMethods";
import moment from "moment";
import excel from "../../components/excel/excel";
import Select from "react-select";
import { useHistory } from "react-router";
import {
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Bar,
    ResponsiveContainer,
    BarChart,
} from "recharts";

export default function AdminReport() {
    const dispatch = useDispatch();
    const history = useHistory();
    const currentUser = useSelector((state) => state.user.currentUser);

    const NumberFormatter = new Intl.NumberFormat("en-GB", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );

    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [salesData, setSalesData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [inputs, setInputs] = useState({
        createdBy: currentUser._id,
        principle: manager
            ? principle
            : currentUser
            ? currentUser._id
            : undefined,
        dateFrom: moment(Date.now())
            .startOf("month")
            .format("YYYY-MM-DD"),
        dateTo: moment(Date.now()).add(1, "days").format("YYYY-MM-DD"),
    });

    const getAdmin = async () => {
        const res =
            process.env.REACT_APP_COUNTRY === "SINGAPORE"
                ? await publicRequest.get("/users/admin", {
                      params: { isAdmin: true },
                  })
                : await publicRequest.get("/users/superadmin", {
                      params: { isAdmin: true },
                  });
        setUsersData(res.data);
    };

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, user: e.value };
        });
    };

    const handleDateFrom = (e) => {
        setInputs((prev) => {
            return { ...prev, dateFrom: e.target.value };
        });
    };
    const handleDateTo = (e) => {
        setInputs((prev) => {
            return { ...prev, dateTo: e.target.value };
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            const res = await getAdminReport(dispatch, inputs);

            if (res) {
                setIsLoaded(true);
                setData(res.product);
                setSalesData(res.sales);
            }
        };
        fetchData();
    }, [dispatch, inputs]);

    useEffect(() => {
        if (usersData[0] === undefined) {
            getAdmin();
        }
    }, [usersData]);

    const columns = [
        {
            field: "outlet",
            headerName: "Outlet",
            width: 300,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="initialPadding">{value ? value : ""}</div>
            ),
        },
        {
            field: "amount",
            headerName: "Amount",
            width: 150,
            sortable: false,
            renderCell: (params) => (
                <div className="capitalize">
                    {params
                        ? params.row.outlet === "HAOFA Tourbillon Singapore"
                            ? "$"
                            : "RM"
                        : "RM"}
                    {NumberFormatter.format(
                        params ? (params.row.amount ? params.row.amount : 0) : 0
                    )}
                </div>
            ),
        },
        {
            field: "unit",
            headerName: "Unit",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="initialPadding">{value ? value : 0}</div>
            ),
        },
        {
            field: "action",
            headerName: "Action",
            width: 300,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                                <div
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    onClick={() =>
                                        history.push("/orders", {
                                            outlet: params
                                                ? params.row.outletId
                                                : undefined,
                                        })
                                    }
                                >
                                    <button className="productListEdit">
                                        View Sales
                                    </button>
                                </div>
                            
                    </>
                );
            },
        },
    ];

    return (
        <>
                    {!data[0] && (
                <h1>
                    No Sales has been made this month yet. Please check back
                    later.
                </h1>
            )}
            <div className="productList">
                {window.location.pathname !== "/" && (
                    <>
                        <div className="samerow">
                            <div className="addProductItem">
                                <input
                                    className="inputFilter"
                                    name="dateFrom"
                                    value={inputs.dateFrom}
                                    type="date"
                                    onChange={(e) => handleDateFrom(e)}
                                />
                            </div>
                            <div className="addProductItem">
                                <input
                                    className="inputFilter"
                                    name="dateTo"
                                    value={inputs.dateTo}
                                    type="date"
                                    onChange={(e) => handleDateTo(e)}
                                />
                            </div>
                            <div className="sameRowItem marginRight">
                                {superadmin && (
                                    <Select
                                        options={usersData.map((user, i) => {
                                            return {
                                                label: `${user.name} - ${user.phoneNumber} - ${user.email}`,
                                                value: user._id,
                                            };
                                        })}
                                        onChange={(e) => handleChange(e)}
                                        placeholder={"Outlet"}
                                        onClick={() =>
                                            usersData[0] === undefined
                                                ? getAdmin()
                                                : {}
                                        }
                                    />
                                )}
                            </div>
                        </div>
                        <div className="block">
                            <button
                                onClick={() => {
                                    excel({
                                        excelData: data,
                                        fileName: `Haofa-Sales-${moment(
                                            inputs.dateFrom
                                        ).format("DD-MM-YYYY")}-to-${moment(
                                            inputs.dateTo
                                        ).format("DD-MM-YYYY")}`,
                                    });
                                }}
                                className="red buttonTop productListButton"
                            >
                                Export
                            </button>
                        </div>
                    </>
                )}
                {isLoaded === true && (
                    <>
                        {data[0] && (
                            <div>
                                <div
                                    style={{
                                        height: window.innerHeight / 2,
                                        width: (window.innerWidth / 100) * 70,
                                    }}
                                >
                                    <ResponsiveContainer
                                        width="100%"
                                        height="100%"
                                    >
                                        <BarChart
                                            width={500}
                                            height={500}
                                            stroke="#fff"
                                            fill="#fff"
                                            data={data}
                                            margin={{
                                                top: 5,
                                                right: 0,
                                                left: 0,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid
                                                stroke="#f5f5f5"
                                                strokeDasharray="3 3"
                                            />
                                            <XAxis dataKey="model" />
                                            <YAxis stroke="#fff" />
                                            <Tooltip />
                                            <Bar
                                                dataKey="amount"
                                                stackId="a"
                                                fill="#1f355e"
                                                label
                                            />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="mt-8 h-30vh w-70vw">
                                    <DataGrid
                                        rows={
                                            salesData
                                        }
                                        disableSelectionOnClick
                                        columns={columns}
                                        pageSize={100}
                                        disableColumnMenu
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
}
