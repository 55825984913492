import { DataGrid } from "@material-ui/data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { transferStocks } from "../../redux/apiCalls";
import { publicRequest } from "../../requestMethods";
import Select from "react-select";
import { getDeliveryWarranties } from "../../redux/apiCalls";

const ModalContainer = styled.div`
    height: 100%;
    width: 100%;
    opacity: 0.75;
    background: black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 100;
`;

const OverlayContainer = styled.div`
    overflow: hidden;
`;

const Overlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    left: 0;
    width: 100%;
    height: 50%;
    top: 25%;
    background-color: rgba(white, 0.5);
    visibility: visible;
`;

const ModalDetails = styled.div`
    background-color: rgba(white, 0.5);
    color: black;
    height: 100%;
    width: 50%;
    display: flex;
    z-index: 102;
`;

const ModalItemContainer = styled.div`
    background-color: white;
    color: black;
    height: 100%;
    width: 100%;
    display: grid;
`;

export default function Transfer() {
    const dispatch = useDispatch();
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );
    const adminOutlet = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser._id : null
    );

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const staff = useSelector((state) =>
    state.user.currentUser ? state.user.currentUser.type === "staff" : null
);


    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );
    const warranties = useSelector((state) => state.warranty.warranties);

    const stockKeeper = useSelector((state) =>
    state.user.currentUser ? state.user.currentUser.isStockKeeper : null
);

    const [isLoaded, setIsLoaded] = useState(false);
    const [inputs, setInputs] = useState({
        model: "",
        userId: 
        manager
        ? principle
        : staff
        ? principle
        : adminOutlet,
        limit: 100,
        page: 1,
        status: "inactive",
        serialNumber: "",
        dateFrom: undefined,
        endOn: undefined,
        outlet: "",
    });
    const [usersData, setUsersData] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [checklist, setChecklist] = useState([]);
    const [editModalState, setEditModalState] = useState(false);
    const [serialNumber, setSerialNumber] = useState(undefined);
    const [outlet, setOutlet] = useState(undefined);
    const [fromOutlet, setFromOutlet] = useState(stockKeeper ? undefined : adminOutlet);

    const getUsers = async () => {
        if (stockKeeper) {
            const res = await publicRequest.get("/users/alladmin", {
                params: { isAdmin: true },
            });
            setUsersData(res.data);
        } else {
            const res = await publicRequest.get("/users/admin", {
                params: { isAdmin: true },
            });
            setUsersData(res.data);
        }

    };

    useEffect(() => {
        const fetchData = async () => {
            if (isLoaded === false) {
                try {
                    const res = await getDeliveryWarranties(dispatch, inputs);
                    if (res) {
                        if (res.payload[0]) {
                            setIsLoaded(true);
                        }
                        setOriginalData(res.payload)
                        setAllData(res.payload);
                        setModalData(res.payload.slice(0, 100));
                        setIsLoaded(true);
                    }
                } catch (error) {
                    console.error("Error fetching warranties:", error);
                }
            }
        };

        fetchData();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (usersData[0] === undefined) {
            getUsers();
        }
         //eslint-disable-next-line
    }, [usersData]);

    const handleOutletChange = (e) => {
        setAllData(
            originalData.filter((warranty) => warranty.outlet._id.includes(e.value))
        );
        setModalData(
            originalData.filter((warranty) => warranty.outlet._id.includes(e.value))
        );
        setFromOutlet(e.value);
        setInputs({...inputs, page: 1});
    };

    const handleChange = (e) => {
        const value = e.target.value.trim(); // Trim whitespace

        if (value === "") {
            setSerialNumber(undefined);
            return; // If empty string, do nothing
        }

        const filteredWarranty = allData.find((warranty) =>
            warranty.serialNumber.includes(value)
        );

        if (filteredWarranty) {
            const newData = allData.filter(
                (warranty) => !warranty.serialNumber.includes(value)
            );
            newData.unshift(filteredWarranty);
            setModalData(newData);
        } else {
            setModalData(allData);
        }

        setSerialNumber(value);
    };

    const hqColumns = [
        {
            field: "serialNumber",
            headerName: "Serial Number",
            width: 200,
            sortable: false,
        },
        {
            field: "productColor",
            headerName: "Model",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value ? value : ""}</div>
            ),
        },
        {
            field: "outlet",
            headerName: "Outlet",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    <div className="capitalize">
                        {value
                            ? value.name
                            : ""}
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value ? value : ""}</div>
            ),
        },
    ];

    return (
        <div className="productList ">
            <div className="samerow">
                    <div className="addProductItem">
                        <input
                            className="inputFilter"
                            name="serialNumber"
                            value={serialNumber}
                            type="string"
                            placeholder="Serial Number"
                            onChange={handleChange}
                        />
                    </div>
                <div className="sameRowItem marginRight">
                    {superadmin && (
                        <Select
                            options={usersData.map((user, i) => {
                                return {
                                    label: `${user.name}`,
                                    value: user._id,
                                };
                            })}
                            onChange={handleOutletChange}
                            placeholder={"Outlet"}
                            onClick={() =>
                                usersData[0] === undefined ? getUsers() : {}
                            }
                        />
                    )}
                </div>
            </div>
            <div className="block">
                <>
                    <button
                        onClick={() => {
                            if (fromOutlet === undefined) {
                                alert("Please select an outlet first.");
                               
                              } 
                               else {
                                setEditModalState(true)
                              }
                        }}
                        className="green buttonTop productListButton"
                    >
                        Transfer
                    </button>
                </>
            </div>
            {isLoaded === true && (
                <DataGrid
                    rows={modalData}
                    checkboxSelection
                    onSelectionModelChange={(ids) => {
                        setChecklist(ids.selectionModel);
                    }}
                    disableSelectionOnClick
                    columns={hqColumns}
                    getRowId={(row) => row._id}
                    pageSize={Number(allData && !fromOutlet ? allData.length : fromOutlet && modalData.length < 100 ? modalData.length : allData.length)}
                    disableColumnMenu
                    rowCount={warranties.totalPages}
                    paginationMode="local"
                    onPageChange={async (newPage) => {
                        inputs.page = newPage.page + 1;
                        setModalData(
                            allData.slice(
                                newPage.page * 100,
                                (newPage.page + 1) * 100
                            )
                            
                        );
                        setInputs((prev) => ({
                            ...prev,
                            page: newPage.page + 1,
                        }));
                    }}
                />
            )}
            <div className="reportContainer">
                <div className="featuredReport blue">
                    <span className="featuredTitle">Total Stock:</span>
                    <div className="featuredReportContainer">
                        <span className="featuredMoney">
                            {" "}
                            {allData && !fromOutlet ? allData.length : fromOutlet && modalData.length < 100 ? modalData.length : allData.length}
                        </span>
                    </div>
                </div>
            </div>
            {editModalState && (
                <OverlayContainer>
                    <ModalContainer
                        onClick={() => setEditModalState(false)}
                    ></ModalContainer>
                    <Overlay>
                        <ModalDetails>
                            <ModalItemContainer>
                                <div className="updateTitle">
                                    <form className="addProductForm">
                                    <Select
                        className="borderBlack"
                        options={usersData.map((user, i) => {
                            return {
                                label: `${user.name} - ${user.country === "malaysia" ?  "MY" : "SG"}`,
                                value: user._id,
                            };
                        })}
                        placeholder={"Outlet Name - Country"}
                        name="receiver"
                        onChange={(e) => setOutlet(e.value)}
                    />
                                    
                                        <div
                                            onClick={() => {
                                                const payload = {
                                                    outlet: outlet,
                                                    fromOutlet,
                                                    warranties: checklist,
                                                };
                                                if (checklist.length === 0) {
                                                alert("Please select at least one item to transfer.");
                                                return;
                                                }
                                                else if (fromOutlet === outlet) {
                                                    alert("You cannot transfer to the same outlet.");
                                                    return;
                                                  }
                                                 else {
                                                transferStocks(payload, dispatch);
                                                }
                                            }}
                                            className="updateProductButton"
                                        >
                                            Transfer Stocks to Outlet
                                        </div>
                                    </form>
                                </div>
                            </ModalItemContainer>
                        </ModalDetails>
                    </Overlay>
                </OverlayContainer>
            )}
        </div>
    );
}
