import { useState, useEffect } from "react";
import "./newUser.css";
import { register } from "../../redux/apiCalls";
import styled from "styled-components";
import moment from "moment";

const ModalContainer = styled.div`
    height: 100%;
    width: 100%;
    opacity: 0.75;
    background: black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 100;
`;

const OverlayContainer = styled.div`
    overflow: hidden;
`;

const Overlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(white, 0.5);
    visibility: visible;
`;

const ModalDetails = styled.div`
    background-color: rgba(white, 0.5);
    color: black;
    height: 100%;
    width: 50%;
    display: flex;
    z-index: 102;
`;

const ModalItemContainer = styled.div`
    background-color: white;
    color: black;
    height: 100%;
    width: 100%;
    display: grid;
`;

const TopBanner = styled.div`
background-image: url(receipt.jpg);
background-size: contain;
color: black;
height: 20vh;
background-position: center;
`

export default function NewUser() {
    const [inputs, setInputs] = useState({});
    const [modalState, setModalState] = useState(false);

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    useEffect(() => {
        if (modalState === true) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [modalState]);

    const handleClick = async (e) => {
        const user = { ...inputs };
        e.preventDefault();
        try {
            register({ user });
        } catch (err) {
            console.log(err);
        }
    }

    const handleModalState = async () => {
        setModalState(true);
    };

    return (
        <>
            <div className="newProduct">
                <h1 className="addProductTitle">New User</h1>
                <div className="addProductForm">
                    <div className="addProductItem">
                        <label className="black required">Full Name</label>
                        <input
                            name="name"
                            type="text"
                            placeholder="Name"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <label className="black required">Email</label>
                        <input
                            name="email"
                            type="text"
                            placeholder="Email"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <label className="black required">Phone Number</label>
                        <input
                            name="phoneNumber"
                            type="text"
                            placeholder="Phone Number"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <label className="black required">Password</label>
                        <input
                            name="password"
                            type="password"
                            placeholder="Password"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <label className="black">Date of Birth (optional)</label>
                        <input
                            name="dob"
                            type="date"
                            placeholder="Date Of Birth"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <label className="black">Address (optional)</label>
                        <input
                            name="lineOne"
                            type="string"
                            placeholder="Line One"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <input
                            name="lineTwo"
                            type="string"
                            placeholder="Line Two"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <input
                            name="zip"
                            type="string"
                            placeholder="Zipcode"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <input
                            name="city"
                            type="string"
                            placeholder="City"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <input
                            name="country"
                            type="string"
                            placeholder="Country"
                            onChange={handleChange}
                        />
                    </div>
                    <button onClick={handleModalState} className="updateProductButton">
                        Create
                    </button>
                </div>
            </div>
            {modalState && (
                <OverlayContainer>
                    <ModalContainer
                        onClick={() => setModalState(false)}
                    ></ModalContainer>
                    <Overlay>
                        <ModalDetails>
                            <ModalItemContainer>
                                <TopBanner></TopBanner>
                                <div className="updateTitle">
                                    <form className="addProductForm">
                                        <div className="addProductItem samerow">
                                            <label className="black">Full Name :</label>
                                            <label className="">{inputs.name}</label>
                                        </div>
                                        <div className="addProductItem samerow">
                                        <label className="black">Email :</label>
                                        <label className="">{inputs.email}</label>
                                        </div>
                                        <div className="addProductItem samerow">
                                        <label className="black">Phone Number:</label>
                                        <label className="">{inputs.phoneNumber}</label>
                                        </div>
                                        <div className="addProductItem samerow">
                                        <label className="black">Password:</label>
                                        <label className=""> ******</label>
                                        </div>
                                        <div className="addProductItem">
                                            <label className="black">
                                                Date of Birth (optional)
                                            </label>
                                            <input
                                                name="dob"
                                                type="date"
                                                value={
                                                    inputs.dob
                                                        ? moment(
                                                              inputs.dob
                                                          ).format("YYYY-MM-DD")
                                                        : undefined
                                                }
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="addProductItem">
                                            <label className="black">Address (optional)</label>
                                            <input
                                                name="lineOne"
                                                type="string"
                                                placeholder={
                                                    inputs.lineOne
                                                        ? inputs.lineOne
                                                        : ""
                                                }
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="addProductItem">
                                            <input
                                                name="lineTwo"
                                                type="string"
                                                placeholder={
                                                    inputs.lineTwo
                                                        ? inputs.lineTwo
                                                        : ""
                                                }
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="addProductItem">
                                            <input
                                                name="zip"
                                                type="string"
                                                placeholder={
                                                    inputs.zip ? inputs.zip : ""
                                                }
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className="addProductItem">
                                            <input
                                                name="city"
                                                type="string"
                                                placeholder={
                                                    inputs.city
                                                        ? inputs.city
                                                        : ""
                                                }
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="addProductItem">
                                            <input
                                                name="country"
                                                type="string"
                                                placeholder={
                                                    inputs.country
                                                        ? inputs.country
                                                        : ""
                                                }
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div
                                            onClick={handleClick}
                                            className="updateProductButton"
                                        >
                                            Confirm
                                        </div>
                                    </form>
                                    
                                </div>
                            </ModalItemContainer>
                        </ModalDetails>
                    </Overlay>
                </OverlayContainer>
            )}
        </>
    );
}
