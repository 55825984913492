import "./sidebar.css";
import {
    LineStyle,
    Timeline,
    AttachMoney,
    CardTravel,
    Assessment,
    QueryBuilder,
    Security,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Sidebar() {
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );

    const admin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isAdmin : null
    );

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const marketing = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "marketing"
            : null
    );

    const accountant = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "accountant"
            : null
    );
    return (
        <div className="sidebar">
            <div className="sidebarWrapper">
                <div className="sidebarMenu">
                    <h3 className="sidebarTitle">Dashboard</h3>
                    <ul className="sidebarList">
                        <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to="/"
                            className="link"
                        >
                            <li className="sidebarListItem active">
                                <LineStyle className="sidebarIcon" />
                                Home
                            </li>
                        </Link>
                        {!accountant && (
                            <>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/pos"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Timeline className="sidebarIcon" />
                                        POS
                                    </li>
                                </Link>
                            </>
                        )}
                    </ul>
                </div>
                <div className="sidebarMenu">
                    <h3 className="sidebarTitle">Warranty</h3>
                    <ul className="sidebarList">
                        {(superadmin || manager || admin) && (
                            <>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/warranties"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Security className="sidebarIcon" />
                                        Warranties
                                    </li>
                                </Link>
                            </>
                        )}
                    </ul>
                </div>
                <div className="sidebarMenu">
                    <h3 className="sidebarTitle">Orders</h3>
                    <ul className="sidebarList">
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            to="/orders"
                            className="link"
                        >
                            <li className="sidebarListItem">
                                <AttachMoney className="sidebarIcon" />
                                Orders
                            </li>
                        </Link>
                        {(superadmin || marketing || accountant) && (
                            <>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/ecommerce"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <CardTravel className="sidebarIcon" />
                                        Ecommerce
                                    </li>
                                </Link>
                            </>
                        )}
                    </ul>
                </div>
                <div className="sidebarMenu">
                    {(superadmin || marketing) && (
                        <h3 className="sidebarTitle">Reports</h3>
                    )}
                    <ul className="sidebarList">
                        {superadmin && (
                            <>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/productreport"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Assessment className="sidebarIcon" />
                                        Report Analysis
                                    </li>
                                </Link>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/report"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <QueryBuilder className="sidebarIcon" />
                                        Monthly Report
                                    </li>
                                </Link>
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}
