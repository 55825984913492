import "./home.css";
import { useSelector } from "react-redux";
import Topbar from "../../components/topbar/Topbar";
import Sidebar from "../../components/sidebar/Sidebar";
import ProductReport from "../productReport/ProductReport";
import AdminReport from "../productReport/AdminReport";

export default function Home() {
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );

    const admin = useSelector((state) =>
    state.user.currentUser ? state.user.currentUser.isAdmin : null
);

const staff = useSelector((state) =>
state.user.currentUser ? state.user.currentUser.type === "staff" : null
);

const manager = useSelector((state) =>
state.user.currentUser
    ? state.user.currentUser.type === "manager"
    : null
);

    return (
        <>
            <Topbar />
            <div className="container">
                <Sidebar />
                <div className="home">
                    {superadmin && <ProductReport />}
                    {(admin || manager || staff) && (!superadmin) && <AdminReport />}
                </div>
            </div>
        </>
    );
}
