const colorNames =  [
    { name: "BLK", color: "Black" },
    { name: "RED", color: "Red" },
    { name: "BLUE", color: "Blue" },
    { name: "DBLU", color: "Dark Blue" },
    { name: "LBLU", color: "Light Blue" },
    { name: "WHE", color: "White" },
    { name: "GRN", color: "Green" },
    { name: "YLW", color: "Yellow" },
    { name: "SE", color: "SE Limited" },
    { name: "ORG", color: "Orange" },
    { name: "GOLD", color: "Gold" },
    { name: "SVR", color: "Silver" },
    { name: "BRED", color: "Black/Red" },
    { name: "BBLU", color: "Black/Blue" },
    { name: "BYLW", color: "Black/Yellow" },
    { name: "BRW", color: "Brown" },
    { name: "PRP", color: "Purple" },
    { name: "BBRW", color: "Black/Brown" },
    { name: "BORG", color: "Black/Orange" },
    { name: "BWHE", color: "Black/White" },
    { name: "BGRN", color: "Black/Green" },
    { name: "SBRW", color: "Silver/Brown" },
    { name: "RBRW", color: "Red/Brown" },
    { name: "WBLU", color: "White/Blue" },
    { name: "WYLW", color: "White/Yellow" },
    { name: "WBRW", color: "White/Brown" },
    { name: "PNK", color: "Pink" },
    { name: "SBLU", color: "Silver/Blue" },
    { name: "SBLK", color: "Silver/Black" },
    { name: "SBRW", color: "Silver/Brown" },
    { name: "BLBR", color: "Blue/Brown" },
    { name: "GBLK", color: "Gold/Black" },
    { name: "GSVR", color: "Gold/Silver" },
    { name: "GBRW", color: "Gold/Brown" },
    { name: "GGRN", color: "Gold/Green" },
    { name: "GWHE", color: "Gold/White" },
    { name: "GBLU", color: "Gold/Blue" },
    { name: "RBLK", color: "Red/Black" },
    { name: "OBLK", color: "Orange/Black" },
    { name: "BGRN", color: "Black/Green" },
    { name: "RGLD", color: "Rose/Gold" },
    { name: "STRAP", color: "STRAP", hexcode: "#00FFFFFF" },
    { name: "WINDER", color: "Watch Winder", hexcode: "#ffffff" },
];

export default colorNames;