import { useLocation } from "react-router-dom";
import "./stock.css";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { transferStock } from "../../redux/apiCalls";
import { publicRequest } from "../../requestMethods";
import Select from "react-select";

const type = [
    {
        label: "Consignment",
        value: "consignment",
    },
    {
        label: "Outright",
        value: "outright",
    },
];

export default function Stock() {
    const location = useLocation();
    const stockId = location.pathname.split("/")[2];
    const [inputs, setInputs] = useState({});
    const [usersData, setUsersData] = useState([]);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, receiver: e.value };
        });
    };

    const handleChangeType = (e) => {
        setInputs((prev) => {
            return { ...prev, type: e.value };
        });
    };

    const handleUpdateStock = async () => {
        await transferStock(stockId, inputs, dispatch);
        window.location.href = "/warranties";
    };

    const getAdmins = async () => {
        const res = await publicRequest.get("/users/admin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    const getAllAdmins = async () => {
        const res = await publicRequest.get("/users/alladmin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    useEffect(() => {
        if (usersData[0] === undefined) {
            if (process.env.REACT_APP_COUNTRY === "SINGAPORE") {
                getAdmins();
            } else {
                getAllAdmins();
            }
        }
    }, [usersData]);

    return (
        <div className="product">
            <h1 className="addProductTitle">Transfer Stock</h1>
            <div className="addProductForm">
                <div className="addProductItem">
                    <label>Transfer To</label>
                    <Select
                        className="borderBlack"
                        options={usersData.map((user, i) => {
                            return {
                                label: `${user.name}`,
                                value: user._id,
                            };
                        })}
                        placeholder={"Outlet Name - Email"}
                        name="receiver"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Type</label>
                    <Select
                        className="borderBlack"
                        options={type.map((type, i) => {
                            return {
                                label: `${type.label}`,
                                value: type.value,
                            };
                        })}
                        placeholder={"Consignment / Outright"}
                        name="type"
                        onChange={handleChangeType}
                    />
                </div>
                <button
                    onClick={handleUpdateStock}
                    className="addProductButton"
                >
                    Transfer Stock
                </button>
            </div>
        </div>
    );
}
