import { DataGrid } from "@material-ui/data-grid";
import { Close, Done } from "@material-ui/icons";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getStockCheck, checkstock } from "../../redux/apiCalls";
import excel from "../../components/excel/excel";
import moment from "moment";
import { publicRequest } from "../../requestMethods";
import Select from "react-select";
import outletList from "../../outletList";

const statusOptions = [
    {
        label: "Checked",
        value: true,
    },
    {
        label: "Not Checked",
        value: false,
    },
];

export default function CheckList() {
    const dispatch = useDispatch();
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );
    const adminOutlet = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser._id : null
    );

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );
    const warranties = useSelector((state) => state.warranty.warranties);
    
    const [isLoaded, setIsLoaded] = useState(false);
    const [inputs, setInputs] = useState({
        model: "",
        userId: manager ? principle : adminOutlet,
        limit: 100,
        page: 0,
        status: "inactive",
        serialNumber: "",
        dateFrom: undefined,
        endOn: undefined,
        outlet: "",
    });
    const [usersData, setUsersData] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [checklist, setChecklist] = useState([]);
    const [serialNumber, setSerialNumber] = useState(undefined);

    const getUsers = async () => {
        const res = await publicRequest.get("/users/admin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    const fetchWarranties = async (dispatch, inputs) => {
        const res = await getStockCheck(dispatch, inputs);
        setModalData(res.payload);
    };

    useEffect(() => {
        const fetchData = async () => {
            const res = await getStockCheck(dispatch, inputs);
            if (res) {
                if (res.payload[0]) {
                    if (
                        res.payload[0].outlet._id ===
                            "6426327094c2157f1ba818b4" ||
                        res.payload[0].outlet._id === "63ff2a38380819f085c75b51"
                    ) {
                        alert("Loading is completed.");
                    }
                    setIsLoaded(true);
                }
                setModalData(res.payload);
                setIsLoaded(true);
            }
        };
        fetchData();
    }, [dispatch, inputs]);

    useEffect(() => {
        if (usersData[0] === undefined) {
            getUsers();
        }
    }, [usersData]);

    const handleCheckChange = (e) => {
        setInputs((prev) => {
            return { ...prev, check: e.value };
        });
    };

    const handleOutletChange = (e) => {
        setInputs((prev) => {
            return { ...prev, outlet: e.value };
        });
    };
    
    
    const handleChange = (e) => {
        setModalData(
            warranties.payload.filter((warranty) =>
                warranty.serialNumber.includes(e.target.value)
            )
        );
        setSerialNumber(e.target.value);
    };

    const handleDateFrom = (e) => {
        setInputs((prev) => {
            return { ...prev, dateFrom: e.target.value };
        });
    };

    const handleEndOn = (e) => {
        setInputs((prev) => {
            return { ...prev, endOn: e.target.value };
        });
    };
    const hqColumns = [
        {
            field: "serialNumber",
            headerName: "Serial Number",
            width: 200,
            sortable: false,
        },
        {
            field: "check",
            headerName: "Check",
            width: 100,
            sortable: false,
            renderCell: ({ value }) => (
                <div className=" productTitleContainer capitalize">
                    {value === true ? <Done /> : <Close />}
                </div>
            ),
        },
        {
            field: "productColor",
            headerName: "Model",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value ? value : ""}</div>
            ),
        },
        {
            field: "outlet",
            headerName: "Outlet",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => {
                const selectedOutlet = outletList.find(
                    (outlet) => outlet.id === value
                );
                return (
                    <div className="capitalize">
                        {value ? selectedOutlet ? selectedOutlet.name : "" : ""}
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value ? value : ""}</div>
            ),
        },
        {
            field: "startDate",
            headerName: "Activated Date",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">
                    {" "}
                    {value ? moment(value).format("DD-MM-YYYY") : ""}
                </div>
            ),
        },
    ];

    const columns = [
        {
            field: "serialNumber",
            headerName: "Serial Number",
            width: 200,
            sortable: false,
        },
        {
            field: "productColor",
            headerName: "Model",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value ? value : ""}</div>
            ),
        },
        {
            field: "user",
            headerName: "User",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value ? value.name : ""}</div>
            ),
        },
        {
            field: "outlet",
            headerName: "Outlet",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value ? value.name : ""}</div>
            ),
        },
        {
            field: "startDate",
            headerName: "Activated Date",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">
                    {" "}
                    {value ? moment(value).format("DD-MM-YYYY") : ""}
                </div>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value}</div>
            ),
        },
    ];

    return (
        <div className="productList ">
            <div className="samerow">
            <div className="addProductItem">
                    <input
                        className="inputFilter"
                        name="serialNumber"
                        value={serialNumber}
                        type="string"
                        placeholder="Serial Number"
                        onChange={handleChange}
                    />
                </div>

                <div className="addProductItem">
                        <input
                            className="inputFilter"
                            name="dateFrom"
                            value={inputs.dateFrom}
                            type="date"
                            onChange={(e) => handleDateFrom(e)}
                        />
                    </div>
                    <div className="addProductItem">
                        <input
                            className="inputFilter"
                            name="endOn"
                            value={inputs.endOn}
                            type="date"
                            onChange={(e) => handleEndOn(e)}
                        />
                    </div>
                <div className="sameRowItem marginRight">
                    {superadmin && (
                        <Select
                            options={usersData.map((user, i) => {
                                return {
                                    label: `${user.name}`,
                                    value: user._id,
                                };
                            })}
                            onChange={handleOutletChange}
                            placeholder={"Outlet"}
                            onClick={() =>
                                usersData[0] === undefined ? getUsers() : {}
                            }
                        />
                    )}
                </div>

                <div className="sameRowItem marginRight">
                    <Select
                        options={statusOptions.map((status, i) => {
                            return {
                                label: status.label,
                                value: status.value,
                            };
                        })}
                        onChange={handleCheckChange}
                        placeholder="Check"
                    />
                </div>

            </div>
            <div className="block">
                    {superadmin && (
                        <>
                                          <button
                        onClick={() => {
                            checkstock(checklist, dispatch);
                        }}
                        className="green buttonTop productListButton"
                    >
                        Check
                    </button>
                            <button
                                onClick={() => {
                                    let payload = [];

                                    modalData.forEach((warranty) => {
                                        const selectedOutlet = outletList.find(
                                            (outlet) => outlet.id === warranty.outlet
                                        );

                                        payload.push({
                                            Outlet: selectedOutlet.name,
                                            Model: warranty.productColor,
                                            SerialNumber: warranty.serialNumber,
                                            Checked : warranty.check === true ? "Checked" : "Not Checked",
                                            Type: warranty.type,
                                        });
                                    });
                                    excel({
                                        excelData: payload,
                                        fileName: "haofa-checklist",
                                    });
                                }}
                                className="red buttonTop productListButton"
                            >
                                Export
                            </button>
                        </>
                    )}
                </div>
            {isLoaded === true && (
                <DataGrid
                    rows={modalData}
                    checkboxSelection
                    onSelectionModelChange={(ids) => {
                        setChecklist(ids.selectionModel);
                    }}
                    disableSelectionOnClick
                    columns={superadmin ? hqColumns : columns}
                    getRowId={(row) => row._id}
                    pageSize={Number(inputs.limit)}
                    disableColumnMenu
                    rowCount={warranties.totalPages}
                    paginationMode="server"
                    onPageChange={async (newPage) => {
                        inputs.page = newPage.page;
                        await fetchWarranties(dispatch, inputs);
                        setInputs((prev) => ({
                            ...prev,
                            page: newPage.page,
                        }));
                    }}
                />
            )}
            {superadmin && (
                <div className="reportContainer">
                    <div className="featuredReport blue">
                        <span className="featuredTitle">Total Stock:</span>
                        <div className="featuredReportContainer">
                            <span className="featuredMoney">
                                {" "}
                                {warranties ? warranties.totalPages : "0"}
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
