import { useEffect, useState } from "react";
import "./pos.css";
import { useDispatch, useSelector } from "react-redux";
import { getProducts, getAccessories } from "../../redux/apiCalls";
import { userRequest } from "../../requestMethods";
import { publicRequest } from "../../requestMethods";
import styled from "styled-components";
import { addProduct, addQuantity, clearCart } from "../../redux/cartRedux";
import { generateSignature } from "../../redux/apiCalls";
import axios from "axios";

const ModalContainer = styled.div`
    height: 100%;
    width: 100%;
    opacity: 0.75;
    background: black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 10;
`;

const OverlayContainer = styled.div`
    overflow: hidden;
`;

const Overlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(white, 0.5);
    visibility: visible;
`;

const ModalDetails = styled.div`
    background-color: rgba(white, 0.5);
    color: black;
    height: 100%;
    width: 50%;
    display: flex;
    z-index: 102;
`;

const EmptyModalDetails = styled.div`
    background-color: rgba(white, 0.5);
    color: black;
    height: 100%;
    width: 50%;
    display: flex;
    z-index: 102;
`;

const ModalItemContainer = styled.div`
    background-color: white;
    color: black;
    height: 100%;
    width: 100%;
    display: grid;
`;

const ColorPickerContainer = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    display: grid;
    grid-template-columns: auto auto;
    z-index: 101;
`;

const ColorPicker = styled.img`
    margin: 20px 10px;
    cursor: pointer;
    width: 90%;
    height: 30vh;
    object-fit: contain;
    background: white;
`;

export default function Pos() {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.product.products);
    const [colorpicker, setColorPicker] = useState(false);
    const [cashierMode, setCashierMode] = useState("sales");
    const [usersData, setUsersData] = useState([]);
    const [warrantyList, setWarrantyList] = useState({});
    const [product, setProduct] = useState({});
    const [color, setColor] = useState({});
    const [file, setFile] = useState(null);
    const [filterList, setFilterList] = useState({
        type: "watch",
    });
    const [discount, setDiscount] = useState(0);
    // eslint-disable-next-line
    const [productfilterCategory, setProductFilterCategory] = useState({
        status: "active",
        model: undefined,
    });

    // eslint-disable-next-line
    const [isProduct, setIsProduct] = useState([
        {
            label: "Product",
            value: true,
        },
        { label: "Warranty", value: false },
    ]);

    // eslint-disable-next-line
    const [paymentType, setPaymentType] = useState([
        {
            label: "Cash",
            value: "cash",
        },
        { value: "onlineTransfer", label: "Online Transfer" },
        { value: "card", label: "Debit / Credit Card" },
        { value: "others", label: "Others" },
    ]);
    // eslint-disable-next-line
    const [quantity, setQuantity] = useState(1);

    const cart = useSelector((state) =>
        state.cart ? state.cart.products : []
    );

    const currentUser = useSelector((state) => state.user.currentUser);

    const total = useSelector((state) =>
        state.cart ? (state.cart.total ? state.cart.total : 0) : 0
    );

    let deconstructedProductList = [];
    cart.forEach((product) => {
        for (let i = 0; i < product.quantity; i++) {
            deconstructedProductList.push(product);
        }
    });

    useEffect(() => {
        if (colorpicker === true) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [colorpicker]);

    useEffect(() => {
        if (usersData[0] === undefined) {
            getUsers();
        }
        dispatch(clearCart());
    }, [usersData, dispatch]);

    useEffect(() => {
        filterList.type === "watch"
            ? getProducts(dispatch, productfilterCategory)
            : getAccessories(dispatch, "");
    }, [dispatch, filterList, productfilterCategory]);

    const getUsers = async () => {
        const res = await publicRequest.get("/users/all", {
            params: {},
        });
        setUsersData(res.data);
    };

    const handleWarrantyChange = (e, i) => {
        setWarrantyList((prev) => {
            return { ...prev, [i]: e.target.value };
        });
    };

    const handleChange = (e) => {
      setDiscount(e.target.value);
    };

    const handlePosCreateOrder = async () => {
        let totalItems = 0;
        cart.forEach((item) => {
            if (item.type === "watch") {
                totalItems = totalItems + 1 * item.quantity;
            }
        });
        if (cart.length === 0) {
            alert("No product added!!");
            return;
        }

        if (Object.keys(warrantyList).length !== totalItems) {
            alert(
                "Please enter the right amount of warranty for all the products"
            );
            return;
        }

        let updatedWatches = cart.map((watch, index) => {
            return {
                ...watch,
                warranty: warrantyList[index],
            };
        });

        if (file == null) {
            return alert("Receipt is required");
        }

        const fileName = new Date().getTime() + file.name;
        const imageresult = await generateSignature();
        const imageFormData = new FormData();

        imageFormData.append("file", file);
        imageFormData.append(
            "publicKey",
            "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
        );
        imageFormData.append("signature", imageresult.data.signature);
        imageFormData.append("expire", imageresult.data.expire);
        imageFormData.append("token", imageresult.data.token);
        imageFormData.append(
            "fileName",
            `${fileName} - ${imageresult.data.expire}`
        );
        imageFormData.append("folder", "haofa");

        const imageUploadResult = await axios.post(
            "https://upload.imagekit.io/api/v1/files/upload",
            imageFormData,
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Accept: "application/json",
                },
            }
        );

        if (currentUser) {
            try {
                const res = await userRequest.post("/orders/warrantyandsales", {
                    userId: "650fd004fff54942628a7c75",
                    products: updatedWatches,
                    amount: total,
                    address: "POS Sales",
                    paymentType: "cash",
                    isProduct: true,
                    cashier: "650fd004fff54942628a7c75",
                    receipt: imageUploadResult.data.url,
                    discount: discount,
                });

                if (res.data) {
                    alert("Your order has been completed");
                    dispatch(clearCart());
                } else {
                    alert("Server issue, please contact support");
                }
            } catch (err) {
                alert(
                    "Failed to create order. Please check if the warranty is correct and belongs to this outlet. Please contact admin if you need support."
                );
            }
        } else {
            alert("Please login before checking out");
        }
    };

    const handleClear = async () => {
        dispatch(clearCart());
    };

    const handleAddProduct = (product, color) => {
        try {
            if (
                cart.find(
                    (item) =>
                        `${item.model}-${item.color.name}` ===
                        `${product.model}-${color.name}`
                ) === undefined
            ) {
                dispatch(
                    addProduct({ ...product, activeColor: color, quantity })
                );
            } else {
                dispatch(
                    addQuantity({ ...product, activeColor: color, quantity })
                );
            }
        } catch {}
    };

    return (
        <div className="pos">
            <div className="userContainer">
                <div className="checkoutButtons">
                    <div
                        className="salesButton lightyellow"
                        onClick={() => setCashierMode("sales")}
                    >
                        Sales
                    </div>
                </div>

                {cashierMode === "sales" && (
                    <>
                        {deconstructedProductList.map((product, i) => {
                            if (product.type === "watch") {
                                return (
                                    <div className="warrantyContainer" key={i}>
                                        <div className="warrantyItem">
                                            {product.model}-{product.color.name}
                                        </div>
                                        <div className="warrantyItem paddingX black">
                                            <input
                                                name="warranty"
                                                type="string"
                                                onChange={(e) =>
                                                    handleWarrantyChange(e, i)
                                                }
                                                defaultValue={""}
                                            />
                                        </div>
                                    </div>
                                );
                            }
                            return undefined;
                        })}
                                        <div className="summary depositPadding">
                    <div className="addDiscountItem">
                        <div className="summarysubtotal">Discount</div>
                        <input
                            name="discount"
                            type="number"
                            onChange={handleChange}
                            defaultValue={""}
                        />
                    </div>
                </div>

                        <div className="warrantyContainer">
                            <div className="warrantyItem paddingX">
                                <input
                                    type="file"
                                    id="file"
                                    onChange={(e) => setFile(e.target.files[0])}
                                />
                            </div>
                        </div>

                        <div className="checkoutButtons">
                            <div
                                className="salesButton sales"
                                onClick={handlePosCreateOrder}
                            >
                                Sales{" "}
                            </div>
                        </div>
                        <div className="checkoutButtons">
                            <div
                                className="salesButton reset"
                                onClick={handleClear}
                            >
                                Reset{" "}
                            </div>
                        </div>
                    </>
                )}
            </div>

            {colorpicker && (
                <OverlayContainer>
                    <ModalContainer></ModalContainer>
                    <Overlay>
                        <EmptyModalDetails
                            onClick={() => setColorPicker(false)}
                        ></EmptyModalDetails>
                        <ModalDetails>
                            <ModalItemContainer>
                                <ColorPickerContainer>
                                    {color
                                        ? color.map((color, i) => (
                                              <ColorPicker
                                                  key={i}
                                                  src={color.img}
                                                  onClick={() => {
                                                      setColorPicker(false);
                                                      handleAddProduct(
                                                          product,
                                                          color
                                                      );
                                                  }}
                                              />
                                          ))
                                        : ""}
                                </ColorPickerContainer>
                            </ModalItemContainer>
                        </ModalDetails>
                    </Overlay>
                </OverlayContainer>
            )}
            <div className="posWrapper">
                <div className="checkoutButtons">
                    <div
                        className="salesButton lightred"
                        onClick={() => setFilterList({ type: "watch" })}
                    >
                        Watches
                    </div>
                    <div
                        className="summary totalButton pointer beige"
                        onClick={() => setFilterList({ type: "accessory" })}
                    >
                        Accessories
                    </div>
                </div>
                <div className="posContainer">
                    {" "}
                    {products.map((product, i) => {
                        if (product.type === filterList.type) {
                            return (
                                <div
                                    key={i}
                                    className="posItem"
                                    onClick={() => {
                                        setColor(product.color);
                                        setProduct(product);
                                        window.scrollTo(0, 0);
                                        setColorPicker(true);
                                    }}
                                >
                                    <div className="justify-center flex">
                                        <img
                                            src={
                                                product.color
                                                    ? product.color[0].img
                                                    : ""
                                            }
                                            alt="productImage"
                                            className="posImg"
                                        />
                                    </div>
                                    <div className="productDetails">
                                        {product.model}
                                    </div>
                                </div>
                            );
                        }
                        return true;
                    })}
                </div>
            </div>
        </div>
    );
}
